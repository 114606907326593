<template>
  <div>
    <v-row>
      <v-col cols="12">
        <redirect class="mb-5" title="Discount" route="website.discount.code" />
        <div class="page-header">
          <Notification v-if="error" :messages="error" type="error" />
          <h2 class="page-title">Create discount code</h2>
        </div>
      </v-col>
      <v-col cols="12" md="8">
        <Code />
        <Options />
        <CustomOptions />
        <Minimum />
        <UsageLimits />
        <CustomerEligibility />
        <ActiveDates v-if="discount.discount_start_date" />
      </v-col>
      <v-col cols="12" md="4" class="px-3 py-3">
        <Summary />
      </v-col>
      <v-col cols="12" class="text-end">
        <v-btn :loading="isLoading" @click="submit" color="primary">Save</v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { type } from 'os';
import { required, minLength, maxLength, minValue, maxValue, email, helpers, decimal } from 'vuelidate/lib/validators';
import moment from 'moment';
import Code from '../components/Code';
import Options from '../components/Options';
import ActiveDates from '../components/ActiveDates';
import CustomerEligibility from '../components/CustomerEligibility';
import Minimum from '../components/Minimum';
import UsageLimits from '../components/UsageLimits';
import CustomOptions from '../components/CustomOptions';
import Summary from '../components/Summary';
import { discountService } from '@/apis/discount';
import { mapState } from 'vuex';

export default {
  components: {
    Code,
    Options,
    ActiveDates,
    CustomerEligibility,
    Minimum,
    UsageLimits,
    CustomOptions,
    Summary,
  },
  data() {
    return {
      setEndDate: false,
      usageLimit: false,
      discount: {
        allocation_limit: 0,
        allocation_method: 'across',
        customer_selection: 'all',
        discount_end_date: '',
        entitled_collection_ids: [],
        entitled_country_ids: [],
        entitled_product_ids: [],
        entitled_variant_ids: [],
        once_per_customer: false,
        prerequisite_collection_ids: [],
        prerequisite_customer_ids: [],
        prerequisite_product_ids: [],
        prerequisite_quantity_range: {},
        prerequisite_shipping_price_range: {},
        prerequisite_subtotal_range: {},
        prerequisite_to_entitlement_quantity_ratio: {},
        prerequisite_variant_ids: [],
        discount_start_date: null,
        status: 'active',
        target_selection: 'all',
        target_type: 'line_item',
        discount_code: '',
        total_sale_count: 0,
        discount_type: 'manual',
        usage_count: 0,
        usage_limit: -1,
        discount_value_type: 'percentage',
        discount_value: 0,
      },
      discountTypes: [
        {
          title: 'Percentage',
          value: 'percentage',
        },
        {
          title: 'Fixed amount',
          value: 'fixed_amount',
        },
        {
          title: 'Free shipping',
          value: 'free_shipping',
        },
      ],
      isLoading: false,
      error: null,
    };
  },
  validations: {
    discount: {
      discount_code: {
        required,
      },
      discount_value: {
        required,
      },
    },
  },
  created() {
    this.discount.discount_start_date = moment(new Date())
      .tz(this.pagePreference.standardTimezone ? this.pagePreference.standardTimezone : 'America/Los_Angeles')
      .format('YYYY-MM-DD HH:mm:ss');
  },
  computed: {
    ...mapState({
      pagePreference: state => state.pagePreference.preference,
    }),
    discountOption: function() {
      return this.discountOptions.find(discount => discount.type == this.discount.discount_value_type);
    },
  },
  methods: {
    async submit() {
      this.isLoading = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          // if (this.discount.discount_start_date) {
          //   this.discount.discount_start_date = moment(new Date(this.discount.discount_start_date))
          //     .utc()
          //     .format('YYYY-MM-DD HH:mm:ss');
          // }
          // if (this.discount.discount_end_date) {
          //   this.discount.discount_end_date = moment(new Date(this.discount.discount_end_date))
          //     .utc()
          //     .format('YYYY-MM-DD HH:mm:ss');
          // }
          let discountData = await discountService.create(this.discount);

          this.$router.push({ name: 'website.discount.code.update', params: { id: discountData.data.id } });
          this.isLoading = false;
        } catch (error) {
          this.isLoading = false;
          this.error = error;
          console.log(error);
        }
      } else {
        this.isLoading = false;
      }
    },
  },
};
</script>
